





















import Vue from 'vue';

export default Vue.extend({
  name: 'Signin',
  props: {
    msg: String,
  },
  data () {
    return {
      scenarioName: 'Harmony',
      pin: '',
      pinFeedback: '',
      displayDelay: null
    }
  },
  computed: {
    uid () {
      return this.$store.getters.uid
    },
    session () {
      return this.$store.getters.session
    },
    team () {
      return this.$store.getters.team
    }
  },
  methods: {
    async signin () {
      const success = await this.$store.dispatch('initSession', {
        scenarioName: this.scenarioName,
        pin: this.pin
      })
      console.log('initSession success', success)
      if (success) {
        // if (this.team.completedBridge) {
        //   this.$router.push('/conclusion')
        // } else if (this.team.completedEntrance) {
        //   this.$router.push('/lobby')
        // } else {
        //   this.$router.push('/entrance')
        // }
        if (this.session.open && (this.session.started || this.session.allowSelfStart) && this.team.completedIntroduction && this.team.lastLocation) {
          this.$router.push({name: this.team.lastLocation})
        } else {
          this.$router.push('/lobby')
        }
      } else {
        this.pinFeedback = 'Invalid Session Name and/or PIN'
        window.setTimeout(() =>{
          this.pinFeedback = ''
        }, 2000)
      }
    },
  },
  // watch: {
  //   async uid (newVal, oldVal) {
  //     if (newVal && !oldVal) {
  //       const inSession = await this.$store.dispatch('continueSession')
  //       console.log('inSession', inSession)
  //       if (inSession) {
  //         this.$watch('team', function (team) {
  //           if (this.team.completedBridge) {
  //             this.$router.push('/conclusion')
  //           } else if (this.team.completedEntrance) {
  //             this.$router.push('/lobby')
  //           } else {
  //             this.$router.push('/entrance')
  //           }
  //         })
  //       }
  //     }
  //   }
  // },
  mounted () {
    // if (this.uid && this.team) {
    //   if (this.team.completedBridge) {
    //     this.$router.push('/conclusion')
    //   } else if (this.team.completedEntrance) {
    //     this.$router.push('/lobby')
    //   } else {
    //     this.$router.push('/entrance')
    //   }
    // }
    // else {
    //   this.displayDelay = window.setTimeout(()=>{
    //     this.displayDelay = null
    //     this.$refs.sidebar.classList.remove('hidden')
    //   }, 1000)
    // }
  },
  beforeDestroy () {
    if (this.displayDelay) window.clearTimeout(this.displayDelay)
  }
});
